import fileDefault from '../assets/file-blank-solid-240.png';
import filePdf from '../assets/file-pdf-solid-240.png';
import filePng from '../assets/file-png-solid-240.png';
import revenueRupee from '../assets/2.png';
import aw from '../assets/aw.png';
import aws from '../assets/aws.png';
import brand from '../assets/brand.png';
import delBoy from '../assets/del.png';
import shop from '../assets/shop.png';
import fill from '../assets/fill.png';
import bag from '../assets/package.png';
import bag2 from '../assets/pack2.png';
export const ImageConfig = {
    default: fileDefault,
    pdf: filePdf,
    png: filePng,
    revenueRupee: revenueRupee,
    aw: aw,
    aws: aws,
    brand: brand,
    delBoy: delBoy,
    shop:shop,
    fill:fill,
    bag:bag,
    bag2:bag2

}