import {React,useState,useEffect} from 'react';
import { useForm } from 'react-hook-form';
import {Form,Button,Row,Col} from 'react-bootstrap';
import '../App.css'


const SecondStep = (props) => {
    const [countries, setCountries] = useState([{"name":"Yes","value":true},{"name":"No","value":false}]);
    const [fssais, setFssais] = useState([{"name":"Yes","value":true},{"name":"No","value":false}]);
    const [owners, setOwners] = useState([{"name":"Yes","value":true},{"name":"No","value":false}]);
    const [selectedCountry, setSelectedCountry] = useState(true);
    const[selectedFssai,setFssai]=useState(true);
    const [selectedOwner,setSelectedOwner]=useState(true);
    
  const { register, handleSubmit, errors } = useForm();
  useEffect(() => {
    const {user}=props;
 Object.keys(user).length == 0 ? props.history.push('/onboard'):console.log("Daju Meals Logs");
   
   }, [selectedCountry,selectedFssai,selectedOwner]);
  const onSubmit = (data) => {
    props.updateUser(data);
    const {user}=props;
    console.log(user);
    props.history.push('/onboard/third');
    

  };

  

  return (
  <div style={
    {
      padding: '20px',
    }
  }>
    <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
            <Col>
        <Form.Group controlId='pref'>
 <Form.Label>Do you want to get updates from Daju Meals on Whatsapp?</Form.Label>
          <Form.Control
          required
            as="select"
            name="pref"
            value={selectedCountry}
            ref={register({
                required: 'required.'
                
              })}
              className={`${errors.pref ? 'input-error' : ''}`}
            onChange={(event) => setSelectedCountry(event.target.value)}
          >
            {countries.map(({ name, value }) => (
              <option value={value} key={value}>
                {name}
              </option>
            ))}
          </Form.Control>
          {errors.pref && (
            <p className="errorMsg">{errors.pref.message}</p>
          )}
          </Form.Group>
          </Col>
          <Col>
          <Form.Group controlId='fssai'>
 <Form.Label>Do you Have a  FSSAI Registration/License?</Form.Label>
 <Form.Control
          required
            as="select"
            name="fssai"
            value={selectedFssai}
            ref={register({
                required: 'required.'
                
              })}
              className={`${errors.own ? 'input-error' : ''}`}
            onChange={(event) => 
                {
                  setFssai(s => !s);                  
                }
            
            
            }
          >
            {fssais.map(({ name, value }) => (
              <option value={value} key={value}>
                {name}
              </option>
            ))}

          </Form.Control>
          {errors.own && (
            <p className="errorMsg">{errors.own.message}</p>
          )}
          </Form.Group>
          </Col>
   
          </Row>
          <Row>
   
      {selectedFssai?(
            <><Col>
              <Form.Group controlId="fssai_exp">
                <Form.Label>FSSAI Expiration Date * </Form.Label>
                <Form.Control

                  type="date"
                  name="fssai_exp"
                  placeholder="Enter Expiration Date"
                  
                  ref={register({
                    required: 'Expiration Date is required.',
                   
                  })}
                  className={`${errors.fssai_exp ? 'input-error' : ''}`} />
                {errors.fssai_exp && (
                  <p className="errorMsg">{errors.fssai_exp.message}</p>
                )}
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Col><Col>
            <Form.Group controlId="fssai_num">
                <Form.Label>FSSAI Number * </Form.Label>
                <Form.Control

                  type="number"
                  name="fssai_num"
                  placeholder="Enter FSSAI Number"
                  autoComplete="off"
                  ref={register({
                    required: 'Number is required.',
                    pattern: {
                      value: /^[0-9 ]+$/,
                      message: 'should contain only numbers.'
                    }
                  })}
                  className={`${errors.fssai_num ? 'input-error' : ''}`} />
                {errors.fssai_num && (
                  <p className="errorMsg">{errors.fssai_num.message}</p>
                )}
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Col></>
           
          ):
        null}

    
          </Row>
          <Row>

          <Col>
          <Form.Group controlId="own">
          <Form.Label>Is the owner or manager same?</Form.Label>
          <Form.Control
          required
            as="select"
            name="own"
            value={selectedOwner}
            ref={register({
                required: 'required.'
                
              })}
              className={`${errors.own ? 'input-error' : ''}`}
            onChange={(event) => 
                {
                  setSelectedOwner(s => !s);
                   console.log("yha hu bhaiya ji")

                    // setFssai(event.target.value)
                  
                }
            
            
            }
          >
            {owners.map(({ name, value }) => (
              <option value={value} key={value}>
                {name}
              </option>
            ))}

          </Form.Control>
          {errors.own && (
            <p className="errorMsg">{errors.own.message}</p>
          )}
            </Form.Group>
          </Col> 
{selectedOwner?(
  <>
  <Col>
  <Form.Group controlId="email">
    <Form.Label>Owner Email</Form.Label>
    <Form.Control
    type='email'
    name='email'
    placeholder="Enter your email address"
    autoComplete="off"
    ref={register({
      required: 'Email is required.',
      pattern: {
        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
        message: 'Email is not valid.'
      }
    })}
    className={`${errors.email ? 'input-error' : ''}`}
  />
  {errors.email && (
    <p className="errorMsg">{errors.email.message}</p>
  )}


  </Form.Group>
  </Col>
  </>
):<>
<Col>
<Form.Group controlId="email">
    <Form.Label>Manager Email</Form.Label>
    <Form.Control
    type='email'
    name='email'
    placeholder="Enter your email address"
    autoComplete="off"
    ref={register({
      required: 'Email is required.',
      pattern: {
        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
        message: 'Email is not valid.'
      }
    })}
    className={`${errors.email ? 'input-error' : ''}`}
  />
  {errors.email && (
    <p className="errorMsg">{errors.email.message}</p>
  )}


  </Form.Group></Col>
</>}


          </Row>
         
          <Row>
<Col>
<Form.Group controlId='entity_name'>
  <Form.Label>Business Entity Name</Form.Label>
  <Form.Control 
  type="text"
  name="entity_name"
  placeholder="Enter Business Entity Name"
  autoComplete="off"
  ref={register({
    required: 'Business Entity Name is required.',
    pattern: {
      value: /^[a-zA-Z0-9 ]+$/,
      message: 'Name should contain only characters and numbers.'
    }
  })}
  className={`${errors.entity_name ? 'input-error' : ''}`} />
  {errors.entity_name && ( <p className="errorMsg">{errors.entity_name.message}</p>
          )}
</Form.Group>
</Col>
<Col>
<Form.Group controlId='entity_address'>
<Form.Label>Business Entity Address</Form.Label>
<Form.Control
name='entity_address'
type='text'
placeholder='Enter Business Entity Address'
autoComplete='off'
ref={register({
  required: 'Business Entity Address is required.',
  pattern: {
    value: /^[a-zA-Z0-9 ]+$/,

    message: 'Address should contain only characters and numbers.'
  } 
})}
className={`${errors.entity_address ? 'input-error' : ''}`}
/>
{errors.entity_address && (<p className="errorMsg">{errors.entity_address.message}</p>)}
</Form.Group>

</Col>
          </Row>
          <Button variant="primary" type="submit" >
                 Next
              </Button>
          </Form>
    </div>
 

  );
};

export default SecondStep;