import React from "react";
import '../style/why.css';
import Card from 'react-bootstrap/Card';

function Why() {
  return (
    <>
  <div className="hos">
     <div className="why-center">
      Why Daju Meals?
      <div className="about-border"/>
    </div>

    <div className="cont2-dajumeals">
      
     <Card border="light">
      
      <Card.Body>
        <Card.Title><h2>Connect with customers</h2></Card.Title>
        <Card.Text>Turn customers into regulars with online visibility,<br/>read their offer and provide exculcive offers</Card.Text>
      </Card.Body>
      </Card>  
      <Card border="light">

      <Card.Body>
        <Card.Title><h2>Increase brand awareness</h2></Card.Title>
        <Card.Text>Boost your visibility. Stand out with exculsive offers</Card.Text>
      </Card.Body>
      </Card> 
      <Card border="light">

      <Card.Body>
        <Card.Title><h2>Access to delivery fleet</h2></Card.Title>
        <Card.Text>Connect with our delivery people, they will provide your delicious meal fast</Card.Text>
      </Card.Body>
      </Card>  
      
{/* <div className="centerItems">
        <img src={ImageConfig['revenueRupee']}/>
       Increase your revenue
        </div>
        
        <div className="centerItems">
        <img src={ImageConfig['aws']}/>
        <p>Increase Brand Awareness</p>
        </div>
       
        <div className="centerItems">
        <img src={ImageConfig['delBoy']}/>
        <p>Access to large delivery fleet</p>
        </div> */}
    


    </div>
    </div>
    </>
  );
}
export default Why;