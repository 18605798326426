
import { useForm } from 'react-hook-form';
import {Form,Button,Row,Col} from 'react-bootstrap';
import '../App.css'
import React from 'react';

const FirstStep = (props) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {

 
    props.updateUser(data);
    const {user}=props;
    console.log(user);
    props.history.push('/onboard/second');
    

  };

  return (
  <div style={
    {
      padding: '20px',
    }
  }>
    <h1>Restaurant Details</h1>
    <div className='p-2'>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
      <Row>
        <Col>
        <Form.Group controlId="rest_name">
        <Form.Label>Restaurant Name</Form.Label>
          <Form.Control 
        
           type="text"
            name="rest_name"
           placeholder="Enter Restaurant Name"
            autoComplete="off"
            ref={register({
                     required: 'Restaurant Name is required.',
                     pattern: {
                       value: /^[a-zA-Z0-9 ]+$/,
                       message: 'Name should contain only characters and numbers.'
                     }
                   })}
                   className={`${errors.rest_name ? 'input-error' : ''}`}
            
          />
           {errors.rest_name && (
            <p className="errorMsg">{errors.rest_name.message}</p>
          )}
         <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
        </Col> 
         <Col>
        <Form.Group controlId="own_name">
        <Form.Label>Owner Name</Form.Label>
          <Form.Control placeholder="Owner Name" 
           type="text"
           name="own_name"
    
           autoComplete="off"
           ref={register({
                    required: 'Name is required.',
                    pattern: {
                      value: /^[a-zA-Z ]+$/,
                      message: 'Name should contain only character'
                    }
                  })}
                  className={`${errors.own_name ? 'input-error' : ''}`}
           
         />
          {errors.own_name && (
           <p className="errorMsg">{errors.own_name.message}</p>
         )}
        
          </Form.Group>
        </Col> 
      </Row> 
     
<Row className='sp'>
<Col>
<Form.Group controlId="own_num">
<Form.Label>Owner Number</Form.Label>
<Form.Control placeholder='Owner Number'
name="own_num"
type="number"
autoComplete='off'
ref={register({
  
          required: 'Number is required.',
          minLength: {
            value: 10,
            message: 'Number should have 10 numbers don`t add +91.'
          },
          maxLength: {
            value: 10,
            message: 'Number should have 10 numbers don`t add +91.'
          },
          pattern: {
            value: /^[0-9 ]+$/,
            message: 'Number should contain only numbers'
          }
})}
className={`${errors.own_num ? 'input-error' : ''}`}
/>
{errors.own_num && (
            <p className="errorMsg">{errors.own_num.message}</p>  
          )}
</Form.Group>
</Col>
<Col>
<Form.Group controlId='wn'>
<Form.Label>Whatsapp number</Form.Label>
<Form.Control 


placeholder='share your whatsapp number'
name='wn'
type='number'
autoComplete="off"
           
ref={register({
  required: 'Number is required.',

  valueAsNumber: true,
  
  pattern: {
    value: /^[0-9 ]+$/,
    message: 'Only contain numbers.'
  },
  minLength: {
    value: 10,
    message: 'Number should have 10 numbers don`t add +91.'
  },
  maxLength: {
    value: 10,
    message: 'Number should have 10 numbers don`t add +91.'
  }
})}
className={`${errors.wn? 'input-error' : ''}`}

/>
{errors.wn && (
<p className="errorMsg">{errors.wn.message}</p>
)}
 
</Form.Group>
</Col>

</Row>
<Row className='sp'>
<Col>
<Form.Group controlId='location'>
<Form.Label>Area Name</Form.Label>
<Form.Control

placeholder='Shop Location'
name='location'
autoComplete="off"
ref={register({
         required: 'Location is required.',
         pattern: {
           value: /^[a-zA-Z0-9 ]+$/,
           message: 'Location should contain only character'
         }
       })}
       className={`${errors.location ? 'input-error' : ''}`}
/>
{errors.location && (
           <p className="errorMsg">{errors.location.message}</p>
         )}
</Form.Group>
</Col>

      </Row>

         <Button variant="primary" type="submit" >
                 Next
              </Button>
          </div>    
    </Form>
    </div>
    </div>
 

  );
};

export default FirstStep;