import React from 'react';
import Button from 'react-bootstrap/Button';
import '../App.css';

export default function Head() {
    const handleClick = () => {
        window.location.href='/onboard'};
    return (
        <>
        <div className='onboard-dajumeals-mains'>

    <div className='onboard-dajumeals-main-logo'>
<h6>Daju Meals<br/>for Merchants</h6>
<p className='hd'> <a href="https://api.whatsapp.com/send/?phone=919915330878" target="_blank">Any Query? </a> 9915330878/9915572026 </p>
    </div>
      <h1 className='onboard-dajumeals-main-headings'>
        Unlock a new revenue stream
        </h1><br/>
        <p className='onboard-dajumeals-main-des'>
        Get listed on Pithoragarh's  online food delivery marketplace today <br/>
        and start selling your delicious food to hungry customers.
        Partner with us today.
        </p>
        

   </div>    
   <footer className="footer">
     <p>Become a Daju Partner today</p>
  
              <Button variant="success" className='m-2' onClick={handleClick}>Let's Go</Button>
  
  </footer>
        </>
    );
}
