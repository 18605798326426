import React from 'react';
import Progress from './Progress';
import '../App.css';

export default function Header() {
    return (
        <>
        <div className='onboard-dajumeals-main mt-4'>
    <div className='onboard-dajumeals-main-logo'>
<img src='https://dajumeals.com/assets/images/dajumeals.png' height="100px" width="100px"></img>
<p> Any Questions? Call us at 9915330878/9915572026</p>
    </div>
    <div className='onboard-dajumeals-main-progress text-center'>
    <h1 className='onboard-dajumeals-main-heading'>
        Partner with Daju Meals 
        </h1><br/>
        <p className='onboard-dajumeals-main-des'>
        Get listed on Pithoragarh's  online food delivery marketplace today
        </p>
        </div>


     
        <Progress/>

   </div>
        </>
    );
}