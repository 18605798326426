import { React, useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import FirstStep from '../component/FirstStep';
import SecondStep from '../component/SecondStep';
import ThirdStep from '../component/ThirdStep';
import Header from '../component/Header';
import Head from '../component/Head';
import Why from '../component/why';
import Reg from '../component/reg';
import swal from 'sweetalert';
import Button from 'react-bootstrap/Button';
import '../style/Cta.css';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const AppRouter = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Listen to authentication state changes
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        // User is signed in
        setUser({
          uid: authUser.uid,
          email: authUser.email,
          displayName: authUser.displayName,
          photoURL: authUser.photoURL
        });
      } else {
        // User is signed out
        setUser(null);
      }
      setLoading(false);
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  const updateUser = (data) => {
    setUser((prevUser) => ({ ...prevUser, ...data }));
  };

  const resetUser = () => {
    setUser(null);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const userData = {
        uid: result.user.uid,
        email: result.user.email,
        displayName: result.user.displayName,
        photoURL: result.user.photoURL
      };
      setUser(userData);
      swal("Welcome", result.user.displayName, "success");
      window.location.href = '/onboard';
    } catch (error) {
      swal("Contact! Daju Meals \n", error.message, "error");
    }
  };

  const HomePage = () => (
    <div>
      <Head />
      <Why />
      <Reg />
      <div className="cta">
        <div className="boxe">
          <h2>Become a Daju Partner today</h2>
          <Button variant="bt" className="bt" onClick={handleClick}>
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      {window.location.pathname === '/' ? (
        <HomePage />
      ) : (
        <div className={
          ['/onboard', '/onboard/second', '/onboard/third'].includes(window.location.pathname) 
            ? "container" 
            : ""
        }>
          {user ? (
            <>
              {['/onboard', '/onboard/second', '/onboard/third'].includes(window.location.pathname) && <Header user={user} />}
              <Switch>
                <Route
                  path="/onboard"
                  exact
                  render={(props) => (
                    <FirstStep {...props} user={user} updateUser={updateUser} />
                  )}
                />
                <Route
                  path="/onboard/second"
                  render={(props) => (
                    <SecondStep {...props} user={user} updateUser={updateUser} />
                  )}
                />
                <Route
                  path="/onboard/third"
                  render={(props) => (
                    <ThirdStep
                      {...props}
                      user={user}
                      updateUser={updateUser}
                      resetUser={resetUser}
                    />
                  )}
                />
                <Route render={() => <HomePage />} />
              </Switch>
            </>
          ) : (
            


            <div class="mobileButton">
              <img src='https://dajumeals.com/assets/images/dajumeals.png' height="100px" width="100px"></img>
          <h1>Partner with us</h1>

              <p>Please sign in to continue</p>
              <Button variant="bt" className="bt2" onClick={handleClick}>
                Sign In
              </Button>
            </div>
          )}
        </div>
      )}
    </BrowserRouter>
  );
};

export default AppRouter;