import React from "react";
import '../style/steps.css';
import { ImageConfig } from "../config/ImageConfig";
function Reg() {
  return (
    <div className="stepo">
      <h1>Get started in 3 easy steps</h1>
      <div className="test">
      <div>
      <img className="imgs" src={ImageConfig.shop} height="40px" width="100px"/>
      <h2>Sign up</h2>
      Start with providing your <br/>restaurant details
      </div>
      <div>
      <img className="imgs"  src={ImageConfig.fill} />
      <h2>Set up</h2>
      Then upload all the<br/> required documents
      </div>
      <div>
      <img  className="imgs"  src={ImageConfig.bag2} />
      <h2>Sell</h2>
      Go live <br/>and start selling
      </div>
      </div>
    </div>
  );
}
export default Reg;