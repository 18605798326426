import React from 'react';
import { withRouter } from 'react-router-dom';
import '../style/Progress.css';
const Progress = ({ location: { pathname } }) => {
  const isFirstStep = pathname === '/onboard';
  const isSecondStep = pathname === '/onboard/second';
  const isThirdStep = pathname === '/onboard/third';
  return (
    <React.Fragment>
      <div className='onboard-container-head'>
      <div className="steps">
        <div className={`${isFirstStep ? 'step active' : 'step'}`}>
          <div>1</div>
          <div>Restaurant <br/> Details</div>
        </div>

      
        <div className={`${isSecondStep ? 'step active' : 'step'}`}>
          <div>2</div>
          <div>Owner/Contact<br/> Details</div>
        </div>
        <div className={`${isThirdStep ? 'step active' : 'step'}`}>
          <div>3</div>
          <div>Menu <br/>Details</div>
        </div>
      </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Progress);