import {React,useState,useEffect,useRef} from "react";
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import uploadImg from '../assets/upload.png';
import { ImageConfig } from '../config/ImageConfig'; 
import {Form,Button,Row,Col} from 'react-bootstrap';
import '../App.css'
import '../style/chips.css'
import swal from 'sweetalert';
import { ref, getDownloadURL, uploadBytesResumable} from "firebase/storage";
import {db,storage} from "../services/firebase.js";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { PDFDocument } from 'pdf-lib'
import download from "downloadjs";
// import fs from 'fs';
const ThirdStep = (props) => {

  //upload image

  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [formEdit,setformEdit]=useState(false);
  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (e) => {
    const MAX_FILE_SIZE = 2048
    const fileSizeKiloBytes = e.target.files[0].size / 1024;
      const newFile = e.target.files[0];
      if (newFile && fileSizeKiloBytes < MAX_FILE_SIZE) {
          const updatedList = [...fileList, newFile];
          setFileList(updatedList);
          console.log(typeof(fileList))

        
      }
      else{
        swal("File size is too large. Please upload a file less than 2MB");
      }
  }

  const fileRemove = (file) => {
      const updatedList = [...fileList];
      updatedList.splice(fileList.indexOf(file), 1);
      setFileList(updatedList);
      console.log(fileList)
      
  }
      //filter chips
      const[items,setItems]=useState([]);
      const[vals,setVals]=useState('');
      const[error,setError]=useState(null);
      const[pureVeg,setpureVeg]=useState(true)
      // const [progresspercent, setProgresspercent] = useState(0);
      const[veg,setVeg]=useState([{"name":"Yes","value":true},{"name":"No","value":false}]);
    const { register, handleSubmit, errors } = useForm();
   ///
 const loadPdf=async()=>{
    // var reader = new FileReader();
    // const formPdfBytes=reader.readAsDataURL('src/assets/Daju_Meals_LOU_names.pdf');
    // const formPdfBytes = fs.readFile('src/assets/Daju_Meals_LOU_names.pdf');
    // Load a PDF with form fields
    const formUrl = 'https://fra1.digitaloceanspaces.com/dajumealstest/Daju_Meals_LOU_names.pdf'
    const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer())
  const pdfDoc = await PDFDocument.load(formPdfBytes);
  // Get the form containing all the fields
  console.log(formPdfBytes);
  const form = pdfDoc.getForm()
  const nameField = form.getTextField('restaurant_name')
  const dateagField = form.getTextField('doa')
  const merchantField = form.getTextField('merchant_name')
  const designationField = form.getTextField('des')
  const dateField = form.getTextField('date');
 const Ab=async()=>{
  console.log("here")
    nameField.setText(props.user.rest_name);
    dateagField.setText(Date().toString());
    merchantField.setText(props.user.own_name);
    designationField.setText(props.designation);
    dateField.setText(Date().toString());
    form.flatten();
    const pdfBytes = await pdfDoc.save()
    console.log(pdfBytes);
    download(pdfBytes, "right-191.pdf", "application/pdf");
  }
  // formEdit==true?Ab():console.log("hey yo")
  nameField.setText(props.user.rest_name);
  dateagField.setText(Date().toString());
  merchantField.setText(props.user.own_name);
  designationField.setText(props.designation);
  dateField.setText(Date().toString());
  form.flatten();
  const pdfBytes = await pdfDoc.save()
  console.log(pdfBytes);
  download(pdfBytes, "right-191.pdf", "application/pdf");
   };
    useEffect(() => {

      const {user}=props;
 Object.keys(user).length == 0 ? props.history.push('/'):console.log("Daju Meals Logs");

  
}, [pureVeg,fileList,setformEdit]);



   const uploadFiles = async(files) => {

 const {user}=props;
 const frref=await db.collection('rquery').add(user);

 console.log(frref);
    const urls=[];
        const promises = []
        files.map((file) => {
            console.log('loop');

            const sotrageRef = ref(storage, `query/${file.name}`);

            const uploadTask = uploadBytesResumable(sotrageRef, file);
            promises.push(uploadTask)
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // const prog = Math.round(
                    //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    // );
                    // setProgresspercent(prog);
                },
                (error) => console.log(error),
                async () => {
                  getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                        db.collection('rquery').doc(frref.id).update({
                          dateTime:firebase.firestore.FieldValue.serverTimestamp(),
                          urls:firebase.firestore.FieldValue.arrayUnion(downloadURLs)
                        });

                 
                        console.log("File available at", downloadURLs);
                   
                    });
                }
            );


        })
        Promise.all(promises)
            .then(() =>{ 
              console.log("Complete");  
              // setformEdit(false)
              swal("Wohoo! Application Submitted", "Thanks for showing interest. \n Team will contact you soon", "success").then(()=>{
                      // loadPdf()
                props.resetUser();
                props.history.push('/');
              });    
            }).catch((err) => {

                swal("Contact!", err.toString(), "error");
              
                console.log(err)
            })
          

    };

    const onSubmit = (data) => {
      console.log("subm;itted")
      swal({
        title:"Data is uploading", 
        text:"Please Wait",
        icon: "https://www.boasnotas.com/img/loading2.gif",
        buttons: false,      
        closeOnClickOutside: false,
        // timer: 3000,
        //icon: "success"
      });
      setformEdit(true);

if(fileList.length>0 && items.length>0){
      const {user}=props;
user.items=items;
user.urls=[];
uploadFiles(fileList);

      }
      else{
        swal(fileList.length==0?"Please upload atleast one image":"Please add atleast one cuisine");
      }
  
    };
  
    const handleKeyDown = evt => {
      console.log(evt.target.value);
      if (["Enter", "Tab", ","].includes(evt.key)) {
        evt.preventDefault();
  
        var value = evt.target.value.trim();
  
        if (value && isValid(value)) {
          setItems([...items, value]);
          setVals('');
         
        }
      }
    };
   function isValid(value) {
  let error=null;
  if(items.includes(value)){
    error="Already added";
  }
  if(error)
  {
    setError(error);
    return false;
  }
  return true;
    }
    const handleChange = evt => {
      setVals(evt.target.value);
      setError(null);
      
    };
  
    const handleDelete = item => {
      setItems(items.filter(i => i !== item));
  
    };
  
    const handlePaste = evt => {
      evt.preventDefault();
  
      var paste = evt.clipboardData.getData("text");
  
        setItems([...items, paste]);
  
  
    };
 
return (
  <div style={
    {
      padding: '20px',
    }
  }>
<Form onSubmit={handleSubmit(onSubmit)}>
<Row>
<Col>
<Form.Group controlId="accPrice">
<Form.Label>What is the min price for ordering?</Form.Label>
<br/>
<small>Ex: Receive orders above  or equal 100</small>
<Form.Control type="number"
name="accPrice"
placeholder="Min Price"
ref={register({ 
  required: 'Please enter the min price',
  })}
className={`${errors.accPrice ? 'input-error' : ''}`}
 />
   {errors.accPrice && (
            <p className="errorMsg">{errors.accPrice.message}</p>
          )}
</Form.Group>


</Col>
<Col>
<Form.Group controlId="pureVeg">
<Form.Label>Is your restaurant pure veg?</Form.Label>
<Form.Control
as="select"
value={pureVeg}

name="pureVeg"
ref={register({ required: true })}
className={`${errors.own ? 'input-error' : ''}`}
onChange={(event) => 
    {
      console.log(pureVeg)
      setpureVeg(s => !s);
       console.log("yha hu bhaiya ji")
       console.log(pureVeg)
    }}
    >
    {veg.map(({ name, value }) => (
      <option value={value} key={value}>
        {name}
      </option>
    ))}

  </Form.Control>

</Form.Group>
</Col>
  </Row>
  <Row>
  <Col>
<Form.Group controlId="minPrice">
<Form.Label>What is the cost for two?</Form.Label>
<br/>
<small>Ex: 100,200.</small>
<Form.Control type="number"
 name="minPrice" 
 placeholder="Estimate Price for two" 
 ref={register({ required: true })} />

</Form.Group>
</Col>
<Col></Col>


  </Row>
 <Row>

            <Col>
<Form.Group controlId="cuisines">
<Form.Label>

What is the type of your cuisine?
<br/>
<small>Ex: Indian, Chinese, Italian, etc.</small>
</Form.Label>
<div className='ro'>
            {items.map(item=>(
               <div className="tag-item" key={item}>
               {item}
               <button
                 type="button"
                 className="button"
                 onClick={() => handleDelete(item)}
               >
                 &times;
               </button>
             </div>
            ))}
             <input
          className={"input " + (error && " has-error")}
          value={vals}
          placeholder="Type or paste and press `Enter`..."
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onPaste={handlePaste}
        />
               {error && <p className="error">{error}</p>}
        </div>
</Form.Group>
</Col>
<Col>
<Form.Group controlId="menu">
<Form.Label>Upload your menu</Form.Label>
  <div
                ref={wrapperRef}
                className="drop-file-input"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div className="drop-file-input__label">
                    <img src={uploadImg} alt="" />
                    <p>Drag & Drop your files here</p>
                </div>
                <input  type="file" value=""  name="menu" accept="image/png, image/jpeg, application/pdf, .doc,.docx,,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,capture" onChange={onFileDrop}/>
            </div>
            {
                fileList.length > 0 ? (
                    <div className="drop-file-preview">
                        <p className="drop-file-preview__title">
                            Ready to upload
                        </p>
                        {
                            fileList.map((item, index) => (
                                <div key={index} className="drop-file-preview__item">
                                    <img src={ImageConfig[item.type.split('/')[1]] || ImageConfig['default']} alt="" />
                                    <div className="drop-file-preview__item__info">
                                        <p>{item.name}</p>
                                        <p>{item.size}B</p>
                                    </div>
                                    <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>x</span>
                                </div>
                            ))
                        }
                    </div>
                ) : null
            }
            </Form.Group>
            </Col>
 </Row>
 <Button variant="primary" type="submit" >
  Submit
              </Button>
</Form>

    </div>
);

};
ThirdStep.propTypes={
  onFileChange:PropTypes.func
}
export default ThirdStep;